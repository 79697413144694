import React from "react";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { WidgetRatingPopup } from "./WidgetRatingPopup";
import { WidgetRatingCat } from "Constants/SVG";

const useStyles = createUseStyles((theme) => ({
	button: {
		position: "fixed",
		zIndex: "9999",
		bottom: "32px",
		left: "20px",
		width: "44px",
		height: "44px",
		padding: 0,
		border: "none",
		borderRadius: "128px",
		cursor: "pointer",
		boxShadow:
			"0 1px 2px 0 rgba(17,24,39,.05),0 3px 3px 0 rgba(17,24,39,.04),0 8px 5px 0 rgba(17,24,39,.03),0 14px 5px 0 rgba(17,24,39,.01),0 21px 6px 0 rgba(17,24,39,0)",
		"&:active": {
			"& svg > g > rect": {
				fill: "#103157",
			},
		},
		"&:disabled": {
			"& svg > g > rect": {
				fill: "#4b5563",
			},
		},
		"&:focus": {
			boxShadow: "0 0 0 4px rgba(63,82,255,0.16)",
		},
	},
	["@media (min-width: 1280px)"]: {
		button: {
			bottom: "1.5rem",
			left: "1.5rem",
			"&:hover": {
				"& svg > g > rect": {
					fill: "#57a5ff",
				},
			},
		},
	},
}));

const WidgetRating = (props) => {
	const { show, time, ip } = props;
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const showWidgetRatingPopup =
		typeof window !== "undefined" && window.localStorage
			? Boolean(localStorage.getItem("showWidgetRatingPopup"))
			: undefined;

	let timeout;

	const onClickCatButton = () => {
		setOpen(true);
		clearTimeout(timeout);
	};

	const onClickCloseButton = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (show && time && !showWidgetRatingPopup) {
			timeout = setTimeout(() => {
				setOpen(true);
				localStorage.setItem("showWidgetRatingPopup", "true");
			}, time * 1000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [time]);

	return (
		<>
			{show && (
				<>
					<button onClick={onClickCatButton} className={classes.button}>
						{WidgetRatingCat}
					</button>
					<WidgetRatingPopup isOpen={open} onClose={onClickCloseButton} ip={ip} />
				</>
			)}
		</>
	);
};

export default WidgetRating;
